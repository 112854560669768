import moment from "moment";
import { useEffect, useState } from "react";

const useGetRecord = (projectType: number, uuid: string) => {
    const [exists, setExists] = useState<boolean>(true);
    const [serialNo, setSerialNo] = useState<number>();
    const [donorName, setDonorName] = useState<string>();
    const [donationArea, setDonationArea] = useState<string>();
    const [designatedSector, setDesignatedSector] = useState<string>();
    const [currency, setCurrency] = useState<string>();
    const [amount, setAmount] = useState<number>();
    const [issueDate, setIssueDate] = useState<Date>();
    const [agent, setAgent] = useState<string>();
    const validProjects = ["DONATION ENG", "DONATION MM", "FACILITATION MM", "FACILITATION ENG"];

    useEffect(() => {
        fetch(`${process.env.REACT_APP_GETREC_API_URL}/oneoff/getrec?prjTp=${projectType}&uuid=${uuid}`)
        .then(res => res.json())
        .then(result => {
            const transactions = JSON.parse(result['body']);
            if(!transactions || transactions.length == 0) {
                setExists(false);
            }
            else {
                const transaction_body = transactions[0];
                setExists(validProjects.includes(transaction_body['project_name'].trim().toUpperCase()));
                setSerialNo(transaction_body['transaction_id']);
                setDonorName(transaction_body['name']);
                const ref3: string = transaction_body['reference3'];
                const donationAreaSuffix: string = ref3 ? `, ${ref3}` : '';
                setDonationArea(`${transaction_body['reference2']}${donationAreaSuffix}`);
                setDesignatedSector(`${transaction_body['reference1']}`);
                setAgent(transaction_body['reference5']);
                setCurrency(transaction_body['currency']);
                setAmount(Number(transaction_body['amount']));
                setIssueDate(moment(transaction_body['transactiontime']).toDate());
            }
        });
    }, [uuid]);

    return { exists, serialNo, donorName, donationArea, designatedSector, currency, amount, issueDate, agent };
}

export default useGetRecord;