import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import useGetRecord from "../hooks/useGetRecord";
import { tokens } from "../theme";

const styles = {
  center: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center"
  },
  header: {
    width: "100%",
    fontSize: {xs: "0.8rem", md: "1rem"},
    fontFamily: "Inter",
    fontWeight: "bold",
    textAlign: "center"
  },
  verificationEng: {
    fontSize: {xs: "1rem", md: "1.2rem"},
    fontFamily: "Inter",
    fontWeight: "bold",
    textAlign: "center",
    color: "green",
    mt: 2
  },
  verificationFailedEng: {
    fontSize: {xs: "1rem", md: "1.2rem"},
    fontFamily: "Inter",
    fontWeight: "bold",
    textAlign: "center",
    color: "red",
    mt: 2
  },
  verificationMM: {
    fontSize: {xs: "0.7rem", md: "1.2rem"},
    fontFamily: "Noto Sans Myanmar",
    fontWeight: "bold",
    textAlign: "center",
    color: "green",
    mt: 2,
    mb: 3
  },
  verificationFailedMM: {
    fontSize: {xs: "0.7rem", md: "1.2rem"},
    fontFamily: "Noto Sans Myanmar",
    fontWeight: "bold",
    textAlign: "center",
    color: "red",
    mt: 2,
    mb: 3
  },
  displayHorizonal: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  labelEng: {
    fontSize: {xs: "0.7rem", md: "1rem"},
    fontFamily: "Inter",
    fontWeight: "bold",
    color: "black",
    textAlign: "center",
    mb: 1,
    lineHeight: 0.5,
  },
  labelMM: {
    fontSize: {xs: "0.7rem", md: "1rem"},
    fontFamily: "Noto Sans Myanmar",
    fontWeight: "bold",
    color: "black",
    textAlign: "center",
    lineHeight: 0.8,
    mb: 1
  },
  value: {
    fontSize: {xs: "0.8rem", md: "1rem"},
    fontFamily: "Inter",
    fontWeight: "bold",
    textAlign: "center",
    color: tokens.color,
    mb: 3,
    mt: 1
  }
}

const Verify = () => {
    const [searchParams] = useSearchParams();
    const projectTypeKey: string = "prjTp";
    const uuidKey: string = "uuid";
    const projectType: number = searchParams.has(projectTypeKey) ? Number(searchParams.get(projectTypeKey)!) : 1;
    const uuidString: string = searchParams.has(uuidKey) ? searchParams.get(uuidKey)! : "";
    const {
        exists,
        serialNo,
        donorName,
        donationArea,
        designatedSector,
        currency,
        amount,
        issueDate,
        agent,
    } = useGetRecord(projectType, uuidString);
    const isAllEnglish = (text: string) =>  /^[A-Za-z0-9,\(\)\[\]\{\}\<\>\s]+$/.test(text.trim());

    // if record does not exist, fail verification
    if(!uuidString || !exists) {
        return (
        <Box
            sx={{
                width: "100%",
                height: "96vh",
                backgroundImage: `url("/images/verification_background.jpeg")`,
                backgroundSize: "auto",
                backgroundPosition: "top",
                padding: 0
            }}
        >
            <Grid
                container
                spacing={0}
                sx={{
                    width: "100%",
                    height: "100%"
                }}
            >
                <Grid item xs={12} sx={{ width: "100%", p: 0, m: 0 }}>
                    <Box sx={{ width: "100%", ...styles.center, p: 0, my: -2 }}>
                        <img
                            height="150"
                            src="/images/MOHADM_logo.png"
                            alt="MOHADM Logo"
                        />
                    </Box>
                    <Typography sx={{...styles.header}}>
                        Republic of the Union of Myanmar
                    </Typography>
                    <Typography sx={{...styles.header}}>
                        National Unity Government
                    </Typography>
                    <Typography sx={{...styles.header}}>
                        Ministry of Humanitarian Affairs and
                    </Typography>
                    <Typography sx={{...styles.header}}>
                        Disaster Management
                    </Typography>
                    <Typography sx={{...styles.verificationFailedEng}}>
                        Verification Failed
                    </Typography>
                    <Typography sx={{...styles.verificationFailedMM}}>
                        လူကြီးမင်းစစ်ဆေးသော ဂုဏ်ပြုမှတ်တမ်းလွှာမှာ
                    </Typography>
                    <Typography sx={{...styles.verificationFailedMM}}>
                        ဝန်ကြီးဌာနမှ တရားဝင်ထုတ်ပေးထားသည့် မှတ်တမ်းမဟုတ်ပါ။
                    </Typography>
                </Grid>
            </Grid>
        </Box>);
    }
    else if(exists && serialNo) {
        return (
            <Box
                sx={{
                    width: "100%",
                    height: "96vh",
                    backgroundImage: `url("/images/verification_background.jpeg")`,
                    backgroundSize: "auto",
                    backgroundPosition: "top",
                    padding: 0,
                }}
            >
                <Grid
                    container
                    spacing={0}
                    sx={{
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <Grid item xs={12} sx={{ width: "100%", p: 0, m: 0 }}>
                        <Box
                            sx={{
                                width: "100%",
                                ...styles.center,
                                p: 0,
                                my: -2,
                            }}
                        >
                            <img
                                height="150"
                                src="/images/MOHADM_logo.png"
                                alt="MOHADM Logo"
                            />
                        </Box>
                        <Typography sx={{ ...styles.header }}>
                            Republic of the Union of Myanmar
                        </Typography>
                        <Typography sx={{ ...styles.header }}>
                            National Unity Government
                        </Typography>
                        <Typography sx={{ ...styles.header }}>
                            Ministry of Humanitarian Affairs and
                        </Typography>
                        <Typography sx={{ ...styles.header }}>
                            Disaster Management
                        </Typography>
                        <Typography sx={{ ...styles.verificationEng }}>
                            Verification Successful
                        </Typography>
                        <Typography sx={{ ...styles.verificationMM }}>
                            အချက်အလက်မှန်ကန်ကြောင်း အသိအမှတ်ပြုအပ်ပါသည်။
                        </Typography>
                        <Typography sx={{ ...styles.labelEng }}>
                            Sr. No.
                        </Typography>
                        <Typography sx={{ ...styles.value }}>
                            {serialNo ? serialNo : "..."}
                        </Typography>

                        <Box sx={{ ...styles.displayHorizonal }}>
                            <Typography
                                sx={{
                                    ...styles.labelEng,
                                    width: { xs: "80px", md: "100px" },
                                    pr: 1,
                                }}
                            >
                                Donor Name
                            </Typography>
                            <Typography sx={{ ...styles.labelMM }}>
                                အလှူရှင်အမည်
                            </Typography>
                        </Box>
                        <Typography
                            sx={{
                                ...styles.value,
                                lineHeight: 2,
                                fontFamily:
                                    donorName && isAllEnglish(donorName!)
                                        ? "Inter"
                                        : "Noto Sans Myanmar",
                            }}
                        >
                            {donorName ? donorName : "..."}
                        </Typography>
                        {donationArea ? (
                            <>
                                <Box sx={{ ...styles.displayHorizonal }}>
                                    <Typography
                                        sx={{
                                            ...styles.labelEng,
                                            width: { xs: "140px", md: "200px" },
                                            pr: 1,
                                        }}
                                    >
                                        Targeted Donation Area
                                    </Typography>
                                    <Typography sx={{ ...styles.labelMM }}>
                                        လှူဒါန်းလိုသည့် နေရာဒေသ
                                    </Typography>
                                </Box>
                                <Typography
                                    sx={{
                                        ...styles.value,
                                        fontFamily:
                                            donationArea &&
                                            isAllEnglish(donationArea!)
                                                ? "Inter"
                                                : "Noto Sans Myanmar",
                                    }}
                                >
                                    {donationArea}
                                </Typography>
                            </>
                        ) : (
                            <></>
                        )}
                        {designatedSector ? (
                            <>
                                <Box sx={{ ...styles.displayHorizonal }}>
                                    <Typography
                                        sx={{
                                            ...styles.labelEng,
                                            width: { xs: "180px", md: "250px" },
                                            pr: 1,
                                        }}
                                    >
                                        Designated Sector of Donation
                                    </Typography>
                                    <Typography sx={{ ...styles.labelMM }}>
                                        လှူဒါန်းသည့် အကြောင်းအရာ
                                    </Typography>
                                </Box>
                                <Typography
                                    sx={{
                                        ...styles.value,
                                        fontFamily:
                                        designatedSector &&
                                            isAllEnglish(designatedSector!)
                                                ? "Inter"
                                                : "Noto Sans Myanmar",
                                    }}
                                >
                                    {designatedSector}
                                </Typography>
                            </>
                        ) : (
                            <></>
                        )}
                        {agent ? (
                            <>
                                <Box sx={{ ...styles.displayHorizonal }}>
                                    <Typography
                                        sx={{
                                            ...styles.labelEng,
                                            width: { xs: "40px", md: "60px" },
                                            pr: 1,
                                            pl: { xs: 3, md: 5 },
                                        }}
                                    >
                                        Agent
                                    </Typography>
                                    <Typography sx={{ ...styles.labelMM }}>
                                        လက်ခံသည့်အဖွဲ့
                                    </Typography>
                                </Box>
                                <Typography
                                    sx={{
                                        ...styles.value,
                                        mt: 0,
                                        fontFamily:
                                            agent && isAllEnglish(agent!)
                                                ? "Inter"
                                                : "Noto Sans Myanmar",
                                    }}
                                >
                                    {agent}
                                </Typography>
                            </>
                        ) : (
                            <></>
                        )}
                        <Box sx={{ ...styles.displayHorizonal }}>
                            <Typography
                                sx={{
                                    ...styles.labelEng,
                                    width: { xs: "60px", md: "80px" },
                                    pr: 1,
                                }}
                            >
                                Currency
                            </Typography>
                            <Typography sx={{ ...styles.labelMM }}>
                                ငွေကြေး
                            </Typography>
                        </Box>
                        <Typography sx={{ ...styles.value }}>
                            {currency}
                        </Typography>
                        <Box sx={{ ...styles.displayHorizonal }}>
                            <Typography
                                sx={{
                                    ...styles.labelEng,
                                    width: { xs: "55px", md: "70px" },
                                    pr: 1,
                                }}
                            >
                                Amount
                            </Typography>
                            <Typography sx={{ ...styles.labelMM }}>
                                ပမာဏ
                            </Typography>
                        </Box>
                        <Typography sx={{ ...styles.value, mt: 0 }}>
                            {amount}
                        </Typography>
                        <Box sx={{ ...styles.displayHorizonal }}>
                            <Typography
                                sx={{
                                    ...styles.labelEng,
                                    width: { xs: "70px", md: "90px" },
                                    pr: 1,
                                }}
                            >
                                Issue Date
                            </Typography>
                            <Typography sx={{ ...styles.labelMM }}>
                                အလှူရက်စွဲ
                            </Typography>
                        </Box>
                        <Typography sx={{ ...styles.value }}>
                            {moment(issueDate).format("DD/MM/YYYY")}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        );
    }
    return <></>;
}

export default Verify;