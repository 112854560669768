import { Box, Grid, Typography } from "@mui/material";
import { styles } from "../theme";

const Home = () => {
    const Text = (props: {fontFamily: string, children: any}) => {
        return (
            <Typography 
                sx={{
                    width: "100%",
                    fontSize: {xs: "1rem", md: "1.6rem"},
                    fontFamily: props.fontFamily,
                    textAlign: "center"
                }}>
                {props.children}
            </Typography>
        )
    }
    return(
    <Box
        sx={{
            width: "100%",
            height: "96vh",
            backgroundImage: `url("/images/verification_background.jpeg")`,
            backgroundSize: "auto",
            backgroundPosition: "left",
        }}
    >
        <Grid
            container
            spacing={0}
            justifyContent="center"
            sx={{
                width: "100%",
                height: "100%"
            }}
        >
            <Grid item xs={8} md={6} sx={{ width: "100%" }}>
                <Box sx={{ width: "100%", ...styles.center, padding: 0, margin: 0 }}>
                    <img
                        height="200"
                        src="/images/MOHADM_logo.png"
                        alt="MOHADM Logo"
                    />
                </Box>
                <Text fontFamily="Inter">
                    The website is an official website of the Ministry of Humanitarian Affairs and <br/> Disaster Management of the National Unity Government, 
                    which helps the public verify the authenticity of the donation certificates.
                </Text>
                <br/>
                <br/>
                <Text fontFamily="Noto Sans Myanmar">
                    <span style={{ lineHeight: "2" }}>
                        အမျိုးသားညီညွတ်ရေးအစိုးရ၊ လူသားချင်း စာနာထောက်ထားရေးနှင့် ဘေးအန္တရာယ်ဆိုင်ရာ စီမံခန့်ခွဲရေးဝန်ကြီးဌာနမှ
                        မှတ်တမ်းတင်ဂုဏ်ပြုထားသော မှတ်တမ်းလွှာများအား အများပြည်သူမှ စစ်ဆေးအတည်ပြုနိုင်ရန် ဖြစ်ပါသည်။
                    </span>
                </Text>
            </Grid>
        </Grid>
    </Box>);
}

export default Home;