import { Box, Grid, Link, Typography } from "@mui/material";
import { styles } from "../theme";

const NotFound = () => {
    const Text = (props: {children: any}) => {
        return (
            <Typography 
                sx={{
                    width: "100%",
                    fontSize: {xs: "1rem", md: "1.6rem"},
                    ...styles.center,
                    fontFamily: "Inter"
                }}>
                {props.children}
            </Typography>
        )
    }
    return (
        <Box
            sx={{
                width: "100%"
            }}
        >
            <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="center"
                sx={{
                    width: "100%",
                    height: "70vh",
                }}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Text>
                        Sorry, page not found...
                    </Text>
                    <Text>
                        Please visit &nbsp;<Link href="/">homepage</Link>&nbsp; for details.
                    </Text>
                </Grid>
            </Grid>
        </Box>
    );
}
export default NotFound;