export const styles = {
    center: {
      display: "flex",
      alignContent: "center",
      justifyContent: "center"
    }
}

export const tokens = {
  color: "#1B76F1"
}