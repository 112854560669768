import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import Header from "../components/header";
import { tokens } from "../theme";

const PageLayout = () => {
    return (
    <Box sx={{ height: "100vh", width: "100%", color: tokens.color }}>
        <Header />
        <Outlet />
    </Box>)
}

export default PageLayout;