import { Box, Grid } from "@mui/material";
import {tokens} from "../theme";
import "./header.css";

const Header = () => {
    return (
        <Grid
            alignContent = "center"
            sx={{
                width: "100%",
                height: {xs: "5vh", md: "4vh"},
                backgroundColor: "#F0F0F0"
            }}
            container
        >
            <Grid
                item
                xs={12}
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "center",
                    justifyContent: "center",
                }}
            >
                <Box sx={{ marginRight: "0.5rem" }}>
                    <img src="/images/emblem-header.png" alt="emblem-header" />
                </Box>
                <Box>
                    <a
                        href="https://nugmyanmar.org/en/"
                        target="_blank"
                        rel="noreferrer"
                        id="nugwebsite_anchor"
                        style={{
                            color: tokens.color
                        }}
                    >
                            An official website of the National Unity Government
                    </a>
                </Box>
            </Grid>
        </Grid>
    );
}

export default Header;